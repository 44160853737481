import { createError, createNS } from "./util";

const ns = createNS("ERRORS/FORM");

export const EMPTY_VALUE = ns("EMPTY_VALUE");
export const INVALID_DATE_FORMAT = ns("INVALID_DATE_FORMAT");
export const INVALID_DATE = ns("INVALID_DATE");
export const INVALID_BIRTH_DATE = ns("INVALID_BIRTH_DATE");
export const INVALID_EMAIL = ns("INVALID_EMAIL");
export const INVALID_FUTURE_DATE = ns("INVALID_FUTURE_DATE");
export const INVALID_PAST_DATE = ns("INVALID_PAST_DATE");
export const INVALID_TIME = ns("INVALID_TIME");
export const INVALID_OPTION = ns("INVALID_OPTION");
export const INVALID_CHECK = ns("INVALID_CHECK");
export const INVALID_POSTAL = ns("INVALID_POSTAL");
export const POSTAL_MISSING = ns("POSTAL_MISSING");
export const INVALID_MATCH = ns("INVALID_MATCH");
export const INVALID_SSN = ns("INVALID_SSN");
export const INVALID_SSN_SERVER = ns("INVALID_SSN_SERVER");
export const INVALID_PERMALINK = ns("INVALID_PERMALINK");
export const DUPLICATE_PERMALINK = ns("DUPLICATE_PERMALINK");
export const INVALID_PHONE_NUMBER_LENGTH = ns("INVALID_PHONE_NUMBER_LENGTH");
export const INVALID_PHONE_NUMBER = ns("INVALID_PHONE_NUMBER");
export const INVALID_PHONE_NUMBER_AREACODE = ns("INVALID_PHONE_NUMBER_AREACODE");
export const INVALID_URL = ns("INVALID_URL");
export const INVALID_COUNTRY = ns("INVALID_COUNTRY");
export const SINGLE_DOCUMENT_UPLOAD_INCOMPLETE_ERROR = ns(
  "SINGLE_DOCUMENT_UPLOAD_INCOMPLETE_ERROR",
);
export const UNSUPPORTED_SELECTION = ns("UNSUPPORTED_SELECTION");
export const OUT_OF_RANGE = ns("OUT_OF_RANGE");
export const ONE_OF_TWO_FIELDS_REQUIRED = ns("ONE_OF_TWO_FIELDS_REQUIRED");
export const NO_SUPPORTED_TITLE_UNDERWRITERS = ns("NO_SUPPORTED_TITLE_UNDERWRITERS");
export const NO_RECORDING_LOCATION = ns("NO_RECORDING_LOCATION");
export const INVALID_INTEGER = ns("INVALID_INTEGER");
export const INVALID_POSITIVE_INTEGER = ns("INVALID_POSITIVE_INTEGER");
export const DUPLICATE_VALUE = ns("DUPLICATE_VALUE");
export const CUSTOM_MESSAGE = ns("CUSTOM_MESSAGE");
export const UNSUPPORTED_TIMEZONE = ns("UNSUPPORTED_TIMEZONE");

export const emptyValue = createError(EMPTY_VALUE);
export const invalidDateFormat = createError(INVALID_DATE_FORMAT);
export const invalidFutureDate = createError(INVALID_FUTURE_DATE);
export const invalidPastDate = createError(INVALID_PAST_DATE);
export const invalidTime = createError(INVALID_TIME);
export const invalidOption = createError(INVALID_OPTION);
export const invalidPostal = createError(INVALID_POSTAL);
export const postalMissing = createError(POSTAL_MISSING);
export const invalidSsn = createError(INVALID_SSN);
export const invalidURL = createError(INVALID_URL);
export const invalidPermalink = createError(INVALID_PERMALINK);
export const duplicatePermalink = createError(DUPLICATE_PERMALINK);
export const invalidPhoneNumberLength = createError(INVALID_PHONE_NUMBER_LENGTH);
export const invalidPhoneNumberAreacode = createError(INVALID_PHONE_NUMBER_AREACODE);
export const outOfRange = createError(OUT_OF_RANGE);
export const oneOfTwoFieldsRequired = createError(ONE_OF_TWO_FIELDS_REQUIRED);
export const invalidInteger = createError(INVALID_INTEGER);
export const invalidPositiveInteger = createError(INVALID_POSITIVE_INTEGER);
export const customMessage = createError(CUSTOM_MESSAGE);
export const unsupportedTimezone = createError(UNSUPPORTED_TIMEZONE);
