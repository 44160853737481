import { useEffect, type ComponentProps } from "react";

import { useWatch, type FieldPath } from "common/core/form";
import { ProofRequirementFormValues } from "common/proof_requirements/common";
import { SigningRequirementEnum } from "graphql_globals";

import { PhoneNumberFields as PhoneNumberFieldsInner } from "../../common/phone";
import { RECIPIENTS } from ".";
import type { UseForm, FormValues } from "../../form";

export function PhoneNumberFields({
  index,
  form,
  transactionSmsAuthRequiredViaCheckbox,
  showTransactionSmsAuthRequiredCheckbox,
  showProofRequirementsName,
  proofRequiredOverride,
  signingRequirementName,
  requiredByConfig,
  ...props
}: {
  index: number;
  form: UseForm;
  transactionSmsAuthRequiredViaCheckbox: boolean;
  showTransactionSmsAuthRequiredCheckbox: boolean;
  signingRequirementName: FieldPath<FormValues>;
  proofRequiredOverride: boolean;
  showProofRequirementsName: FieldPath<FormValues>;
  requiredByConfig: boolean;
} & Omit<
  ComponentProps<typeof PhoneNumberFieldsInner>,
  "countryCodeFieldName" | "phoneFieldName" | "control" | "required"
>) {
  const { control } = form;
  const recipient = `${RECIPIENTS}.${index}` as const;
  const countryCodeFieldName = `${recipient}.countryCode` as const;
  const phoneFieldName = `${recipient}.phone` as const;

  const clearErrors = () => {
    // Clear errors from previously required
    form.clearErrors(phoneFieldName);
    form.clearErrors(countryCodeFieldName);
  };

  const proofRequirement = useWatch({
    control,
    name: `${recipient}.proofRequirement`,
  });

  const signingRequirement = useWatch({
    control,
    name: signingRequirementName,
  });

  const showProofRequirements = useWatch({
    control,
    name: showProofRequirementsName,
  });

  useEffect(() => {
    // Form value will be present even if checkbox not shown. We only want to clear errors if checkbox is shown
    if (!showTransactionSmsAuthRequiredCheckbox) {
      return;
    }
    clearErrors();
  }, [transactionSmsAuthRequiredViaCheckbox]);

  useEffect(() => {
    clearErrors();
  }, [proofRequirement]);
  const transactionSmsAuthRequiredViaProofReq =
    proofRequirement?.includes(ProofRequirementFormValues.SMS) ||
    proofRequirement?.includes(ProofRequirementFormValues.IAL2);

  // The checkbox is being shown (notarization transaction) and the checkbox is checked
  const authRequiredViaCheckbox =
    showTransactionSmsAuthRequiredCheckbox && transactionSmsAuthRequiredViaCheckbox;

  // the proof requirements are visible for an esign notarization signer with the toggle on or for a proof transaction
  const authOptionVisible =
    (showProofRequirements && signingRequirement !== SigningRequirementEnum.NOTARIZATION) ||
    proofRequiredOverride;

  // the sms auth option is selected and proof requirements are visible to an esign signer
  const esignAuthRequirement = transactionSmsAuthRequiredViaProofReq && authOptionVisible;

  // one of the above conditions or it is required by config
  const required = authRequiredViaCheckbox || esignAuthRequirement || requiredByConfig;

  return (
    <PhoneNumberFieldsInner
      {...props}
      required={required}
      countryCodeFieldName={countryCodeFieldName}
      phoneFieldName={phoneFieldName}
      control={control}
    />
  );
}
