import { FormattedMessage } from "react-intl";

import { SummaryDetailWrapper } from "common/details/summary";

import Styles from "./index.module.scss";

export function ActivationLink({ activationLink }: { activationLink: string | null }) {
  return (
    <SummaryDetailWrapper
      term={
        <FormattedMessage
          id="b4770d1b-fbe7-4093-b3fe-d1f749f1a1bf"
          defaultMessage="Activation link"
        />
      }
      definition={
        <span className={Styles.activationLink}>
          {activationLink ? (
            activationLink
          ) : (
            <FormattedMessage id="62f94a5b-7b19-4181-9c20-e12c44e93e1a" defaultMessage="N/A" />
          )}
        </span>
      }
    />
  );
}
