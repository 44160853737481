import { useCallback, useEffect, useState } from "react";
import {
  Subject,
  from,
  takeUntil,
  debounceTime,
  switchMap,
  tap,
  filter,
  catchError,
  startWith,
} from "rxjs";

import { validateEmail as asyncValidateEmail } from "common/core/warnings/email_typo";
import { validateEmailFormatFunction } from "validators/account";
import { useApolloClient, type ApolloClient } from "util/graphql";
import { captureException } from "util/exception";
import type { AddressType } from "graphql_globals";

import TitleAgentQuery from "./index.graphql";

enum EmailAddressEventTypes {
  INITIAL,
  CHANGE,
}

export type TitleAgentLookup = {
  index?: number | null;
  id?: string | null;
  error?: TitleLookupErrors | null;
  email?: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  titleAgencyId?: string;
  titleAgencyName?: string;
  phoneNumber?: string | null;
  address?: AddressType;
  type?: EmailAddressEventTypes;
  inboxEmail?: string | null;
  isSetup?: boolean;
  domainMatched?: boolean;
};

export enum TitleLookupErrors {
  NOT_FOUND = "user_not_found",
  NOT_TITLE_AGENT = "user_not_title_agent",
  EMAIL_DOMAIN_NOT_VALID = "email_domain_not_valid",
}

type AddressLookupData = { email: string; index: number | null; type: EmailAddressEventTypes };

const getTitleAgentLookupPromise = (
  client: ApolloClient<unknown>,
  email: string,
  emailEventType: EmailAddressEventTypes,
  index: number | null,
) =>
  client
    .query({
      query: TitleAgentQuery,
      variables: {
        email,
      },
    })
    .then(({ data }) => ({
      data,
      type: emailEventType,
      index,
    }));

const useTitleAgencyLookup = (initialCollaboratorEmail: string) => {
  const client = useApolloClient();
  const [titleAgentLookup, setTitleAgentLookupData] = useState<TitleAgentLookup | null>(null);
  const [loadingState, setLoadingState] = useState(false);
  let addressEvent$: Subject<AddressLookupData>;

  useEffect(() => {
    addressEvent$ = new Subject();
    const titleAgencyLookup$ = addressEvent$.pipe(
      tap((val) => {
        // We place this tap here to immediately blank out some fields
        // when email becomes invalid. But, we don't want to affect the user's
        // debounced typing
        if (!validateEmailFormatFunction(val)) {
          setTitleAgentLookupData(null);
        }
      }),
      debounceTime(1000),
      startWith({
        email: initialCollaboratorEmail,
        index: 0,
        type: EmailAddressEventTypes.INITIAL,
      }),
      filter((address) => validateEmailFormatFunction(address.email)),
      switchMap((addressEvent) => {
        setLoadingState(true);
        return from(
          new Promise<{
            lookupData?: Awaited<ReturnType<typeof getTitleAgentLookupPromise>>;
            lookupError?: TitleLookupErrors;
          }>((res) => {
            asyncValidateEmail(addressEvent.email).then((err) => {
              if (err) {
                res({
                  lookupError: TitleLookupErrors.EMAIL_DOMAIN_NOT_VALID,
                });
              } else {
                res(
                  getTitleAgentLookupPromise(
                    client,
                    addressEvent.email,
                    addressEvent.type,
                    addressEvent.index,
                  ).then((data) => ({
                    lookupData: data,
                  })),
                );
              }
            });
          }),
        ).pipe(takeUntil(addressEvent$));
      }),
      catchError((err) => {
        setLoadingState(false);
        const error = new Error(`[Title Agency Lookup Service Error]: ${err}`);
        captureException(error);
        throw error;
      }),
    );

    const subscription = titleAgencyLookup$.subscribe(({ lookupData, lookupError }) => {
      if (lookupError) {
        setTitleAgentLookupData({ error: lookupError });
      } else if (lookupData) {
        const { data, type, index = 0 } = lookupData;
        const titleAgentInfo = data?.viewer?.titleAgentByEmail;
        if (!titleAgentInfo) {
          setTitleAgentLookupData(null);
          return;
        }

        const {
          id,
          error,
          email,
          address,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          organization_id: titleAgencyId,
          organization_name: titleAgencyName,
          phone_number: phoneNumber,
          inbox_email: inboxEmail,
          is_setup: isSetup,
          domain_matched: domainMatched,
        } = titleAgentInfo;

        setTitleAgentLookupData({
          index,
          id,
          error,
          email,
          firstName,
          middleName,
          lastName,
          titleAgencyId,
          titleAgencyName,
          phoneNumber,
          address,
          type,
          inboxEmail,
          isSetup,
          domainMatched,
        });
      }

      setLoadingState(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onEmailChange = useCallback((newEmail: string, index: number | null) => {
    addressEvent$.next({
      email: newEmail,
      index,
      type: EmailAddressEventTypes.CHANGE,
    });
  }, []);

  return {
    onEmailChange,
    titleAgentLookup,
    titleAgentLookupLoading: loadingState,
  };
};

export default useTitleAgencyLookup;
