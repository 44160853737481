import { FormattedMessage } from "react-intl";
import { type ReactNode } from "react";

import { AnnotationDesignationType, DocumentBundleMembershipRole } from "graphql_globals";
import IllustrationModal from "common/modals/illustration_modal";
import WorkflowModal from "common/modals/workflow_modal";
import FillDocumentImage from "assets/images/designation-illustrations.svg";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";

import type {
  DocumentSignaturesOrganizationTransaction as Transaction,
  DocumentSignaturesOrganizationTransaction_document_bundle_documents_edges_node as Document,
} from "./organization_transaction_fragment.graphql";
import { scrollToSection, type Validation } from "../util";
import { DOCUMENT_SECTION_ID } from "../../sections/documents";
import { isPlaceOrder } from "../../form";

function hasMissingSignerDesignations(
  documents: Document[],
  customerSigners: Transaction["customerSigners"],
) {
  const signersWithDesignations = new Set();

  const signerDesignations = documents
    .flatMap((doc) => doc.annotationDesignations.edges)
    .filter(
      ({ node }) =>
        node.signerRole.role === DocumentBundleMembershipRole.SIGNER &&
        node.type === AnnotationDesignationType.SIGNATURE,
    );

  signerDesignations.forEach(({ node }) => {
    const designationIndex = node.signerRole.index;
    // In certain cases like white text tagging, we use index based designations (0, 1, 2, etc.) since the
    // templates are created before the signers exist. the designations get properly assigned once the txn is sent
    const index = parseInt(designationIndex, 10);
    if (!isNaN(index)) {
      if (index < customerSigners.length) {
        signersWithDesignations.add(customerSigners[index].id);
      }
    } else {
      signersWithDesignations.add(designationIndex);
    }
  });

  return customerSigners.filter((signer) => !signersWithDesignations.has(signer.id)).length > 0;
}

export const validateDocumentSignatures: Validation<Transaction> = ({
  transaction,
  setSubmitErrorModal,
  config,
}) => {
  return new Promise((resolve) => {
    const placeOrder = isPlaceOrder({
      placeOrder: transaction.placeOrder,
      transaction,
      config,
    });
    if (placeOrder) {
      return resolve({ status: "passed" });
    }

    const documents = transaction.document_bundle!.documents.edges.map((edge) => edge.node);
    const annotationIsOn = documents.some((doc) => doc.customerCanAnnotate);
    const hasDesignations = documents.some((doc) => doc.annotationDesignations.totalCount > 0);

    let errorMessage;
    if (!annotationIsOn && !hasDesignations) {
      errorMessage = (
        <FormattedMessage
          id="becd2fcc-7e29-44aa-9ac2-2163046672c1"
          defaultMessage="Fields show your signers where to sign or fill in information on the document."
        />
      );
    } else if (!annotationIsOn) {
      if (hasMissingSignerDesignations(documents, transaction.customerSigners)) {
        errorMessage = (
          <FormattedMessage
            id="7892635a-e2e7-441b-bc5c-57a814966c03"
            defaultMessage="You must add at least one signature field for each signer before sending."
          />
        );
      }
    }

    if (errorMessage) {
      setSubmitErrorModal(
        <ErrorModal
          onCancel={() => {
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onAcknowledgeError={() => {
            scrollToSection(DOCUMENT_SECTION_ID);
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          errorMessage={errorMessage}
        />,
      );
    } else if (annotationIsOn && !hasDesignations) {
      setSubmitErrorModal(
        <WarningModal
          onCancel={() => {
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onAcknowledgeError={() => {
            scrollToSection(DOCUMENT_SECTION_ID);
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onComplete={() => {
            setSubmitErrorModal(null);
            resolve({ status: "passed" });
          }}
        />,
      );
    } else {
      resolve({ status: "passed" });
    }
  });
};

function WarningModal({
  onCancel,
  onComplete,
  onAcknowledgeError,
}: {
  onCancel: () => void;
  onComplete: () => void;
  onAcknowledgeError: () => void;
}) {
  return (
    <IllustrationModal
      src={FillDocumentImage}
      title={
        <FormattedMessage
          id="a5c8c5b5-2b58-4d5b-93dc-8aba9677d430"
          defaultMessage="Before you send, add fields for your signers"
        />
      }
      onClose={onCancel}
      buttons={[
        <Button key="documents" variant="primary" buttonColor="action" onClick={onAcknowledgeError}>
          <FormattedMessage
            id="8ab88ef4-91dc-48a0-958a-320c892e8abc"
            defaultMessage="Go to documents"
          />
        </Button>,
        <Button key="send" buttonColor="action" variant="secondary" onClick={onComplete}>
          <FormattedMessage
            id="011a3ec7-8458-453f-a123-e94f10e38325"
            defaultMessage="Send without fields"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="7f611bb0-3715-47ac-9b14-406313c8688b"
        defaultMessage="Fields show your signers where to sign or fill in information on the document."
      />
    </IllustrationModal>
  );
}

function ErrorModal({
  onCancel,
  onAcknowledgeError,
  errorMessage,
}: {
  onCancel: () => void;
  onAcknowledgeError: () => void;
  errorMessage: ReactNode;
  title?: ReactNode;
}) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="a795a316-92d1-48f0-b0da-a61898c7c0e5"
          defaultMessage="Add signature fields for your signers"
        />
      }
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      buttons={[
        <Button key="documents" variant="primary" buttonColor="action" onClick={onAcknowledgeError}>
          <FormattedMessage
            id="e3290439-6248-46af-9495-0cd796fa43e8"
            defaultMessage="Go to documents"
          />
        </Button>,
      ]}
    >
      <AlertMessage kind="danger">{errorMessage}</AlertMessage>
    </WorkflowModal>
  );
}
