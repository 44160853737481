import { FormattedMessage } from "react-intl";

import { DashboardContent, DashboardTitle } from "common/dashboard";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";

import IdentityCRMDashboardQuery from "./index.query.graphql";

export function IdentityCRMDashboard({ organizationId }: { organizationId: string }) {
  const { data, loading } = useQuery(IdentityCRMDashboardQuery, {
    variables: { organizationId },
  });

  if (loading || !data?.organization) {
    return <LoadingIndicator />;
  }

  return (
    <DashboardContent>
      <DashboardTitle
        title={
          <FormattedMessage
            id="70f36c43-6bef-46ad-add3-17f5dc37fd3a"
            defaultMessage="Identity CRM"
          />
        }
        description={
          <FormattedMessage
            id="e42347d1-6741-4fc4-bc3d-ab215d881b1a"
            defaultMessage="View all user identities created via your transactions. Monitor risk score."
          />
        }
      />
    </DashboardContent>
  );
}
