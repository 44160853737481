import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import type { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { CURRENT_PORTAL } from "constants/app_subdomains";
import { PS1583_ATTESTATION } from "constants/feature_gates";
import { getParentPathByOffset, newPathWithPreservedSearchParams } from "util/location";
import Apps from "constants/applications";
import { Breadcrumb } from "common/core/breadcrumb";
import { TransactionStatus } from "common/dashboard/columns";
import { renderTransactionTypeWithVariant } from "common/details/summary";
import { Heading, Substyle } from "common/core/typography";
import { useFeatureFlag } from "common/feature_gating";
import { useMatchScreenClass } from "common/core/responsive";
import AlertMessage from "common/core/alert_message";
import type { OrganizationTypeEnum } from "graphql_globals";
import { recipientFullName } from "util/recipient_name";
import { useSignTransactionsEnabled } from "util/feature_detection";
import CopyButton from "common/copy_button";

import { type OrganizationTransactionDetailsHeader as OrganizationTransaction } from "./index_fragment.graphql";
import Styles from "./index.module.scss";
import { TransactionDetailsAlertMessages } from "./alerts";

type TransactionDetailsHeaderProps = {
  transaction: OrganizationTransaction;
  transactionDetailsActions: ReactNode;
  showActionErrorMessage?: boolean;
  viewerOrganization?: { organizationType: OrganizationTypeEnum } | undefined | null;
  refetch?: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  breadcrumbLabel: {
    id: "d6fd35c3-d1ba-45e0-baa7-dc6bb277cd74",
    defaultMessage: "Transactions breadcrumbs",
  },
  currentPageBreadcrumbLabel: {
    id: "efea182a-2157-4b96-83d0-26572e778790",
    defaultMessage: "{name} transaction details",
    values: { name: "name" },
  },
  sendAndManage: {
    id: "9129a99d-74dc-4dbf-ab9d-1ad07782b072",
    defaultMessage: "Send & manage",
  },
  myClosings: {
    id: "4f32963c-cb77-49cd-8fea-1ca1ba7a32e3",
    defaultMessage: "My closings",
  },
  transactions: {
    id: "476b91ba-ad23-43ab-b3a0-cf68c502496e",
    defaultMessage: "Transactions",
  },
  nameNotProvided: {
    id: "613cbac0-8f39-426a-912f-93023bc57529",
    defaultMessage: "Name not provided",
  },
});

export function TransactionDetailsHeader({
  transaction,
  transactionDetailsActions,
  showActionErrorMessage,
  viewerOrganization,
  refetch,
}: TransactionDetailsHeaderProps) {
  const intl = useIntl();
  const isMedium = useMatchScreenClass("xs", "sm", "md");
  const {
    name,
    id,
    status,
    detailedStatus,
    transactionType,
    transactionVariant,
    isMortgage,
    requiresNsaMeeting,
  } = transaction;
  const isTitlePortal = CURRENT_PORTAL === Apps.TITLE_AGENCY;
  const isLenderPortal = CURRENT_PORTAL === Apps.LENDER;
  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;
  const ps1583AttestationEnabled = useFeatureFlag(PS1583_ATTESTATION);
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const customerSigners = transaction.customerSigners;
  const { documentBundle, contacts } = transaction;

  const firstSignerName = recipientFullName(
    contacts[0],
    intl.formatMessage(MESSAGES.nameNotProvided),
    documentBundle,
  );

  const multipleSignersLabel =
    customerSigners.length > 1 ? (
      <>
        {" "}
        <FormattedMessage
          id="2a5d2d9c-7f8b-4c1e-9e3f-7b4b6f6b8c4c"
          defaultMessage="and {count} {count, plural, one {other} other {others}}"
          values={{
            count: customerSigners.length - 1,
          }}
        />
      </>
    ) : null;

  const breadcrumbLinkLabel = () => {
    if (isTitlePortal || isLenderPortal) {
      return intl.formatMessage(MESSAGES.myClosings);
    } else if (isKeystone) {
      return intl.formatMessage(MESSAGES.transactions);
    }
    return intl.formatMessage(MESSAGES.sendAndManage);
  };
  const { pathname } = useLocation();
  const transactionTablePath = getParentPathByOffset(pathname, 2);
  const backRoute = newPathWithPreservedSearchParams(transactionTablePath);

  return (
    <>
      <TransactionDetailsAlertMessages
        transaction={transaction}
        viewerOrganization={viewerOrganization}
        refetch={refetch}
      />
      <div className={Styles.headerContainer}>
        <div className={Styles.headerWrapper}>
          <div className={Styles.headerInfo}>
            <div className={Styles.headerBreadcrumb}>
              <Breadcrumb
                data-automation-id="transaction-details-breadcrumb-nav"
                size="small"
                breadcrumbLabel={intl.formatMessage(MESSAGES.breadcrumbLabel)}
                breadcrumbItems={[
                  { label: breadcrumbLinkLabel(), url: backRoute },
                  {
                    label: id,
                    url: pathname,
                    optionalA11yLabel: intl.formatMessage(MESSAGES.currentPageBreadcrumbLabel, {
                      name,
                    }),
                  },
                ]}
              />
              {isKeystone && <CopyButton className="Breadcrumb--copy" value={id} />}
            </div>
            <Heading
              className={Styles.transactionName}
              level="h1"
              textStyle={isMedium ? "headingFour" : "headingThree"}
            >
              {name}
            </Heading>
            <Substyle textStyle="headingFive">
              {firstSignerName}
              {multipleSignersLabel}
            </Substyle>
            <div className={Styles.transactionInfo}>
              <TransactionStatus
                data-automation-id="transation-status"
                status={status}
                detailedStatus={detailedStatus}
              />
              <span data-automation-id="transaction-type">
                {renderTransactionTypeWithVariant(
                  transactionType,
                  transactionVariant,
                  isMortgage,
                  requiresNsaMeeting,
                  intl,
                  ps1583AttestationEnabled,
                  signTransactionsEnabled,
                )}
              </span>
            </div>
          </div>
          <div className={Styles.transactionActions}>{transactionDetailsActions}</div>
        </div>
        {showActionErrorMessage && (
          <AlertMessage kind="danger" centerText className={Styles.archivedError}>
            <FormattedMessage
              id="b328978c-176d-4900-ae27-712b9d986515"
              defaultMessage="Sorry, something went wrong. Please try again."
            />
          </AlertMessage>
        )}
      </div>
    </>
  );
}
