import { useCallback, useState, memo } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";
import classnames from "classnames";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { userFullName } from "util/user";
import compose from "util/compose";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedFormRow } from "common/form/elements/row";
import type { FormError } from "errors/util";
import { usePermissions } from "common/core/current_user_role";
import { DescriptionListItem } from "common/core/description_list";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import Icon from "common/core/icon";

import SetSignerNameMutation from "./set_signer_name_mutation.graphql";
import styles from "./transaction_customer_name.module.scss";
import { NO_NAME_MESSAGE } from "../utils";

const ERRORS = (error: string) => {
  switch (error) {
    case "description_is_too_long":
      return "descriptionTooLong";
    case "signing_completed":
      return "signingCompleted";
    default:
      return "generalError";
  }
};

const MESSAGES = defineMessages({
  nameOnTransaction: {
    id: "d22c4a08-3d9c-406a-8a68-d3a54d2ab4ba",
    defaultMessage: "Name on transaction",
  },
  firstName: {
    id: "3f40c9be-855e-4721-94af-7f9fd31b981c",
    defaultMessage: "First Name",
  },
  middleName: {
    id: "e7fa8557-b541-4939-bfd8-6c0ca666491f",
    defaultMessage: "Middle Name",
  },
  lastName: {
    id: "309614ef-66c7-43a2-b700-f2db885679c9",
    defaultMessage: "Last Name",
  },
  descriptionPlaceholder: {
    id: "99f1bb08-0e87-4637-9beb-eb53d9ddf77d",
    defaultMessage: "Who requested this change?",
  },
  descriptionTooLong: {
    id: "c9fa983c-0e6e-43ad-8e5d-036f1030ca7d",
    defaultMessage: "The description must be less than 256 characters.",
  },
  signingCompleted: {
    id: "2eab6b57-4ca3-47ce-bfd3-c81947292e84",
    defaultMessage: "The signer has already completed their portion of the transaction.",
  },
  generalError: {
    id: "7c7f06e7-d4cb-428d-8869-5ce1a2444df5",
    defaultMessage: "There was an issue with changing the name. Please reach out to engineering.",
  },
  editNameButtonLabel: {
    id: "c48cf2d4-29d8-4410-bdd1-48e50d2dc4e3",
    defaultMessage: "Edit recipient name",
  },
});

type Props = {
  transactionCustomer: TransactionCustomer;
  onUpdate: () => void;
};
type TransactionCustomer = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
};
type FormValues = {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  description: string | null;
};

function validate(values: FormValues) {
  return composeValidators(
    validatePresence({ field: "firstName", label: "First Name" }),
    validatePresence({ field: "lastName", label: "Last Name" }),
    validatePresence({ field: "description", label: "Requester" }),
  )(values);
}

function TransactionCustomerName({
  transactionCustomer,
  onUpdate,
  handleSubmit,
  change,
}: Props & InjectedFormProps<FormValues, Props, FormError>) {
  const intl = useIntl();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showError, setShowError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { hasPermissionFor } = usePermissions();

  const setSignerNameMutation = useMutation(SetSignerNameMutation);

  const clearForm = () => {
    setShowEditForm(false);
    setShowError(null);
  };

  const setSignerName = useCallback(
    (fv: FormValues) => {
      setShowError(null);
      setIsLoading(true);
      return setSignerNameMutation({
        variables: {
          input: {
            transactionCustomerId: transactionCustomer.id,
            firstName: fv.firstName!,
            middleName: fv.middleName,
            lastName: fv.lastName!,
            description: fv.description!,
          },
        },
      })
        .then(() => {
          clearForm();
          return onUpdate();
        })
        .catch((error: Error) => {
          if (!isGraphQLError(error)) {
            captureException(error);
          }
          const err = isGraphQLError(error) ? error.graphQLErrors[0] : error;
          setShowError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [transactionCustomer, onUpdate],
  );

  const handleEditName = () => {
    change("firstName", transactionCustomer.firstName);
    change("middleName", transactionCustomer.middleName);
    change("lastName", transactionCustomer.lastName);
    setShowEditForm(true);
  };

  const nameContent = userFullName(transactionCustomer) || intl.formatMessage(NO_NAME_MESSAGE);
  const editNameButton = hasPermissionFor("editSignerName") && (
    <LinkStyledButton
      className={classnames(!isTxnDetailsRedesign && styles.button)}
      underlined={false}
      onClick={handleEditName}
      aria-label={intl.formatMessage(MESSAGES.editNameButtonLabel)}
    >
      {isTxnDetailsRedesign ? (
        <Icon name="pencil-filled" />
      ) : (
        <FormattedMessage id="f5f30a9f-6732-482a-9dc1-9e7d1c9abed2" defaultMessage="Edit" />
      )}
    </LinkStyledButton>
  );

  return (
    <>
      {isTxnDetailsRedesign ? (
        <DescriptionListItem
          term={
            <>
              {intl.formatMessage(MESSAGES.nameOnTransaction)} {editNameButton}
            </>
          }
          definition={nameContent}
          automationId="transaction-recipient-name"
        />
      ) : (
        <DeprecatedDetailGridSection>
          <DeprecatedDetailGridRow title={intl.formatMessage(MESSAGES.nameOnTransaction)}>
            <div className={styles.row}>
              <div>{nameContent}</div>
              {editNameButton}
            </div>
          </DeprecatedDetailGridRow>
        </DeprecatedDetailGridSection>
      )}

      {showEditForm && (
        <WorkflowModal
          large
          title={
            <FormattedMessage
              id="f522e5c5-449a-4c6b-930a-5f6c4e33932e"
              defaultMessage="Update Signer's Name On Transaction"
            />
          }
          buttons={[
            <Button
              key="TransactionCustomerName-cancel"
              variant="tertiary"
              buttonColor="dark"
              onClick={clearForm}
              disabled={isLoading}
            >
              <FormattedMessage id="fc526128-8572-47e4-9de3-65125038aea9" defaultMessage="Cancel" />
            </Button>,
            <Button
              key="TransactionCustomerName-agree"
              buttonColor="danger"
              variant="primary"
              onClick={handleSubmit(setSignerName)}
              isLoading={isLoading}
            >
              <FormattedMessage
                id="d2713b09-e027-4f84-a990-d9a28e2759f4"
                defaultMessage="Accept & Change"
              />
            </Button>,
          ]}
        >
          <>
            <FormattedMessage
              id="5ac0d5a9-898d-4c08-9d9a-1c2cc0413b59"
              defaultMessage="You are about to change {name}'s name. Please take note of the following:"
              values={{ name: userFullName(transactionCustomer) }}
            />
            <ul className={styles.list}>
              <li>
                <FormattedMessage
                  id="43a48ce1-9c27-4b0c-bc10-80f8ee2911f7"
                  defaultMessage="This change will be logged for security and tracking purposes."
                />
              </li>
              <li>
                <FormattedMessage
                  id="03a117de-d188-46ef-aaba-f65f35b6bc22"
                  defaultMessage="This will not change the user's name on their account, only for this transaction."
                />
              </li>
              <li>
                <FormattedMessage
                  id="b93cf247-0c09-4e5c-9b23-0b102f320dc2"
                  defaultMessage="This will delete any text that the user has added to the documents (annotations) and it will also restore designations/tags. If a meeting has occurred, the annotations will remain, including the eSign Consent Forms for other signers."
                />
              </li>
              <li>
                <FormattedMessage
                  id="ffe42a97-59db-4ca2-a0b0-81f7956920d9"
                  defaultMessage="This will erase the user's existing signature assets and allow them create new ones."
                />
              </li>
              <li>
                <FormattedMessage
                  id="7a7c6c9e-6a47-40c3-9ff6-91ff3ee077fc"
                  defaultMessage="This will also update signature designations to have the correct name."
                />
              </li>
            </ul>
            {showError && (
              <div className={styles.error}>{intl.formatMessage(MESSAGES[ERRORS(showError)])}</div>
            )}
            <form name="SetSignerNameForm" className={styles.form}>
              <DeprecatedFormRow>
                <FormGroup fields={["firstName", "middleName", "lastName"]} disableFormRowStyle>
                  <DeprecatedMultipartRow>
                    <DeprecatedMultipartColumn width={5}>
                      <DeprecatedTextField
                        id="firstName"
                        name="firstName"
                        placeholder={intl.formatMessage(MESSAGES.firstName)}
                        placeholderAsLabel
                        useStyledInput
                        autoComplete="off"
                      />
                    </DeprecatedMultipartColumn>

                    <DeprecatedMultipartColumn width={3}>
                      <DeprecatedTextField
                        id="middleName"
                        name="middleName"
                        placeholder={intl.formatMessage(MESSAGES.middleName)}
                        placeholderAsLabel
                        useStyledInput
                        autoComplete="off"
                      />
                    </DeprecatedMultipartColumn>

                    <DeprecatedMultipartColumn width={5}>
                      <DeprecatedTextField
                        id="lastName"
                        name="lastName"
                        placeholder={intl.formatMessage(MESSAGES.lastName)}
                        placeholderAsLabel
                        useStyledInput
                        autoComplete="off"
                      />
                    </DeprecatedMultipartColumn>
                  </DeprecatedMultipartRow>
                  <FormGroupErrors fields={["firstName", "middleName", "lastName"]} />
                </FormGroup>
              </DeprecatedFormRow>
              <DeprecatedFormRow>
                <FormGroup fields={["description"]} disableFormRowStyle>
                  <DeprecatedTextField
                    id="description"
                    name="description"
                    placeholder={intl.formatMessage(MESSAGES.descriptionPlaceholder)}
                    placeholderAsLabel
                    useStyledInput
                    autoComplete="off"
                  />
                  <FormGroupErrors fields={["description"]} />
                </FormGroup>
              </DeprecatedFormRow>
            </form>
          </>
        </WorkflowModal>
      )}
    </>
  );
}

export default compose(
  memo,
  reduxForm<FormValues, Props, FormError>({
    form: "SetSignerNameForm",
    validate,
  }),
)(TransactionCustomerName);
