import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import type { SigningRequirementEnum } from "graphql_globals";
import FormattedSigningRequirement from "common/core/format/formatted_signing_requirements";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DescriptionListItem } from "common/core/description_list";

type Props = {
  signingRequirement: SigningRequirementEnum;
};

export function SigningRequirements({ signingRequirement }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  const signingRequirementsLabel = (
    <FormattedMessage
      id="a07edb58-8de9-48a2-9324-5e9fbfbe518c"
      defaultMessage="Signing requirements"
    />
  );

  const signingRequirementsContent = (
    <FormattedSigningRequirement signingRequirement={signingRequirement} />
  );

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={signingRequirementsLabel} definition={signingRequirementsContent} />
  ) : (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title={signingRequirementsLabel}>
        {signingRequirementsContent}
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}
