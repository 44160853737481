import { parse, isValid, isFuture, isAfter, endOfDay, isExists } from "date-fns";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";

import { composeValidators } from "util/form";
import { getScale } from "util/number";
import { isURL } from "util/string";
import { US_ZIP_FORMAT, CA_ZIP_FORMAT, SSN_FORMAT, PERMALINK_FORMAT } from "constants/validation";
import { COUNTRIES_WITHOUT_POSTCODES } from "constants/form";
import {
  emptyValue,
  invalidDateFormat,
  invalidFutureDate,
  invalidTime,
  invalidOption,
  invalidPostal,
  postalMissing,
  invalidSsn,
  invalidPermalink,
  invalidPhoneNumberLength,
  invalidPhoneNumberAreacode,
  invalidURL,
  outOfRange,
  invalidInteger,
  invalidPositiveInteger,
  oneOfTwoFieldsRequired,
} from "errors/form";

import createValidation from "./util";

export const validatePresence = createValidation(
  ({ value }) => Boolean(value !== null && value !== undefined && value.toString().trim()),
  emptyValue,
);

export const validateSocialSecurityNumber = createValidation(
  ({ value }) => SSN_FORMAT.test(value),
  invalidSsn,
);

export const validatePermalink = createValidation(
  ({ value }) => PERMALINK_FORMAT.test(value),
  invalidPermalink,
);

/** is a _string_ value a valid date */
export const validateStringDate = createValidation(
  ({ value, format }) => isValid(parse(value || "", format, new Date())),
  invalidDateFormat,
  "yyyy-MM-dd",
);

export const validatePhoneNumberStartsWithValidAreacode = createValidation(
  ({ value }) => !value?.startsWith("1"),
  () => {
    // Construct a standalone object so that redux-form updates errors.
    // This works around a bug in redux-form where lodash.isEqual compares new Errors() as equal
    // incorrectly not updating the UI when two phone errors have different types.
    const err = invalidPhoneNumberAreacode();
    return { type: err.type };
  },
);

export const validatePhoneNumberLength = ({ isInternational, ...args }) =>
  createValidation(({ value }) => {
    const trimmedValue = (value || "").trim().replace(/[()-]/g, "").replaceAll(" ", "");
    return isInternational ? Boolean(trimmedValue) : trimmedValue.length === 10;
  }, invalidPhoneNumberLength)(args);

export const validateFutureDay = ({ monthField, dayField, yearField, ...args }) => {
  return createValidation(({ values }) => {
    const year = values[yearField]?.toString().padStart(4, "0");
    const month = values[monthField]?.toString().padStart(2, "0");
    const day = values[dayField]?.toString().padStart(2, "0");
    const date = new Date(`${year}-${month}-${day}`);

    return (
      isValid(date) &&
      isFuture(date) &&
      isExists(Number(values[yearField]), Number(values[monthField]) - 1, Number(values[dayField]))
    );
  }, invalidFutureDate)(args);
};

export const validateFutureDate = (args) => {
  return createValidation(({ values }) => {
    const date = values[args.field];
    return isValid(date) && isAfter(endOfDay(date), new Date());
  }, invalidFutureDate)(args);
};

export const validateTime = ({ hourField, minuteField, meridiemField, ...args }) => {
  return createValidation(({ values }) => {
    const hour = Number(values[hourField]);
    const minute = Number(values[minuteField]);
    const meridiem = values[meridiemField];
    return (
      hour >= 1 &&
      hour <= 12 &&
      minute >= 0 &&
      minute <= 59 &&
      (meridiem === "AM" || meridiem === "PM")
    );
  }, invalidTime)(args);
};

/** @type {(options: { field: string, label: string; format?: Record<string, unknown> }) => (values: Record<string, unknown>) => Record<string, unknown>} */
export const validateOption = createValidation(
  ({ value, format = {} }) => Object.keys(format || {}).includes(value),
  invalidOption,
);

export const validateNumberInRange = (args) => {
  const { min, max, step } = args;
  const stepScale = getScale(step);
  const scaleBy = 10 ** stepScale;
  const scaledStep = Math.round(step * scaleBy);
  return createValidation(({ value }) => {
    const num = parseFloat(value);
    if (isNaN(num)) {
      return false;
    }
    const numScale = getScale(value);
    if (numScale > stepScale) {
      return false;
    }
    // safe to round with numScale <= stepScale
    return min <= num && num < max + step && Math.round(num * scaleBy) % scaledStep === 0;
  }, outOfRange)(args);
};

// Conditional validations
/** @type {<T>(opts: { field?: string; condition: (value: T) => boolean; validation: Function }) => (values: Record<string, unknown>) => Record<string, unknown>} */
export function validateIf({ field = null, condition, validation }) {
  return function (values) {
    return condition(get(values, field)) ? validation(values) : {};
  };
}

export function validateIfAny({ fields, condition, validation }) {
  return function (values) {
    return (fields || []).some((field) => condition(get(values, field))) ? validation(values) : {};
  };
}

// Zip & Postal validations
const validateZip = createValidation(
  ({ value, format = /^\d{5}$/ }) => format.test(value),
  invalidPostal,
);

export const validateUSZipCode = ({ ...args }) =>
  validateZip({
    label: "ZIP",
    format: US_ZIP_FORMAT,
    ...args,
  });

const validateCanadianPostal = ({ ...args }) =>
  validateZip({
    label: "Postal",
    format: CA_ZIP_FORMAT,
    ...args,
  });

export const validatePostalCodeRelativeToCountry = ({ field, prefix, missingLabel }) =>
  composeValidators(
    // US
    validateIf({
      field: `${prefix}country`,
      condition: (val) => val === "US",
      validation: validateUSZipCode({ field }),
    }),

    // Canada
    validateIf({
      field: `${prefix}country`,
      condition: (val) => val === "CA",
      validation: validateCanadianPostal({ field }),
    }),

    // Countries w/ zip codes
    validateIf({
      field: `${prefix}country`,
      condition: (val) => val && !COUNTRIES_WITHOUT_POSTCODES.includes(val),
      validation: validatePresence({ field, error: postalMissing, label: missingLabel }),
    }),
  );

export const validateURL = createValidation(({ value }) => value && isURL(value), invalidURL);

const validateInteger = createValidation(
  ({ value }) => Number.isSafeInteger(value),
  invalidInteger,
);

export const validatePositiveInteger = createValidation(
  ({ value, ...args }) => validateInteger({ value, ...args }) && value >= 0,
  invalidPositiveInteger,
);

export const validatePresenceOfOne = ({
  primaryField,
  secondaryField,
  primaryLabel,
  secondaryLabel,
}) => {
  return createValidation(({ values }) => {
    return get(values, primaryField) || get(values, secondaryField);
  }, oneOfTwoFieldsRequired)({ label1: primaryLabel, label2: secondaryLabel, field: primaryField });
};
