import { useMemo } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { usePermissions } from "common/core/current_user_role";
import { useFeatureFlag } from "common/feature_gating";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { Feature, UserRole } from "graphql_globals";

import { IdentityCRMDashboard } from "./dashboard";
import { IdentityCRMProfile } from "./profile";
import IdentityCRMQuery, {
  type IdentityCRM_organization_Organization as Organization,
} from "./index.query.graphql";

export function useShowIdentityCRM(organization: Organization | null) {
  const { hasPermissionFor, currentUserRole } = usePermissions();
  const identityCRMEnabled = useFeatureFlag("identity-crm", false);

  return useMemo(
    () =>
      (organization?.featureList.includes(Feature.IDENTITY_CRM) &&
        hasPermissionFor("viewIdentityCRM") &&
        identityCRMEnabled) ||
      currentUserRole === UserRole.ADMIN,
    [organization?.featureList, hasPermissionFor, currentUserRole],
  );
}

function Content({ organizationId }: { organizationId: string }) {
  return (
    <Routes>
      <Route index element={<IdentityCRMDashboard organizationId={organizationId} />}></Route>
      <Route path="profile" element={<IdentityCRMProfile />}></Route>
    </Routes>
  );
}

export default function IdentityCRM() {
  const [activeOrganizationId] = useActiveOrganization();
  const { globalID } = useParams();

  const organizationId = globalID || activeOrganizationId!;

  const { data, loading } = useQuery(IdentityCRMQuery, {
    variables: { organizationId },
  });

  const organization = data?.organization as Organization | null;
  const hasIdentityCRMFeature = useShowIdentityCRM(organization);

  if (loading || !organization) {
    return <LoadingIndicator />;
  }
  if (!hasIdentityCRMFeature) {
    return <Navigate to="/" />;
  }

  return <Content organizationId={organizationId} />;
}
