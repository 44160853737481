import { type ReactNode, type ReactElement, useRef, type MutableRefObject } from "react";
import { useIntl, FormattedMessage, type IntlShape, defineMessages } from "react-intl";

import {
  Feature,
  OrganizationTransactionCreationSource,
  OrganizationTransactionDetailedStatus,
  UserAction,
  OrganizationTypeEnum,
  MortgageTransactionType,
  CompletionRequirement,
  OrganizationTransactionStatus,
  OrganizationTransactionVariant,
  RequiredFeature,
} from "graphql_globals";
import Link from "common/core/link";
import { usePermissions } from "common/core/current_user_role";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { formattedTransactionType } from "common/mortgage/transactions/utils";
import AdminTransactionDetailsDateTimes from "common/details/summary/datetimes";
import PriceBreakdownWithRefund from "common/transactions/price_breakdown/with_refund";
import PaymentStatus from "common/transactions/payment_status";
import PayerType from "common/transactions/payer_type";
import TransactionMarkFree from "common/transactions/mark_free";
import TransactionHacks from "common/hacks/transaction";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import SignerFailedKbaAlert, {
  useSignerFailedKba,
} from "common/transactions/signer_failed_kba_alert";
import { formattedTransactionVariant } from "common/core/format/formatted_transaction_variant";
import { SECTIONS } from "constants/details/summary";
import APP from "constants/applications";
import Env from "config/environment";
import { isNotaryDocumentDownloadProhibited } from "common/notary/capacity";
import { useMutation } from "util/graphql";
import { userFullName } from "util/user";
import { ESIGN_ONLY_TRANSACTIONS } from "constants/transaction";
import { DescriptionList, DescriptionListItem } from "common/core/description_list";
import { Card, CardHeading } from "common/core/card";
import { Badge } from "common/core/badge";
import { DetailsDescriptionList } from "common/transactions/details/common";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Paragraph } from "common/core/typography";
import Button from "common/core/button";
import { downloadImageAsPNG } from "util/canvas_text";
import { useCopy } from "util/clipboard";

import SigningTimeSchedule from "./signing_time_schedule";
import AdminTools from "./admin_tools";
import type { DocumentBundleForTransactionDetailsSummary } from "./index_fragment.graphql";
import AdminUpdateOrganizationTransactionMutation from "./admin_update_organization_transaction_mutation.graphql";
import TransactionOrganizationDisplay from "./transaction_organization_display";
import { DeprecatedSummary } from "./deprecated/deprecated_index";
import { PropertyInfoContainer } from "./property_information";
import { ReferralInfoContainer } from "./referral_info";
import Styles from "./index.module.scss";
import { DocumentPreviewContainer } from "./document_preview";
import { CustomEmailDetails } from "./custom_email";
import { EasyLinkInfoContainer } from "./easylink_info";

type Props = {
  bundle: DocumentBundleForTransactionDetailsSummary;
  entry?: string;
  viewer: {
    user: null | {
      id: string;
      notaryProfile?: Parameters<typeof isNotaryDocumentDownloadProhibited>[0];
      organization?: null | {
        organizationType: OrganizationTypeEnum;
        canShowPaymentInTransactionForInvoicing: boolean;
      };
    };
  };
  refetch: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  qrCodeAlt: {
    id: "d4c1bea3-31b3-4da0-abb0-6c3215d2eeba",
    defaultMessage: "QR Code to transaction in the Verify portal",
  },
});

const { hasHacksEnabled } = Env;
const CREATION_SOURCE_LABELS: Readonly<
  Partial<Record<OrganizationTransactionCreationSource, ReactElement>>
> = Object.freeze({
  [OrganizationTransactionCreationSource.NOTARIZE]: (
    <FormattedMessage
      id="5e432784-ce07-42ee-a96e-9a820165e0ec"
      defaultMessage="Created from Notarize"
    />
  ),
  [OrganizationTransactionCreationSource.NOTARIZE_API]: (
    <FormattedMessage
      id="3d3276f6-faf1-45f3-9aa5-5e9d714a167d"
      defaultMessage="Created from Notarize (API)"
    />
  ),
  [OrganizationTransactionCreationSource.EASYLINK]: (
    <FormattedMessage
      id="937d81ca-3ecd-44da-b425-0445d3fd5d90"
      defaultMessage="Created from Notarize (EasyLink)"
    />
  ),
  [OrganizationTransactionCreationSource.ENCOMPASS]: (
    <FormattedMessage
      id="5eea2c27-9e13-4e46-bba2-4c08d9a93af7"
      defaultMessage="Created from Encompass"
    />
  ),
  [OrganizationTransactionCreationSource.DROPBOX]: (
    <FormattedMessage
      id="5d5a0a62-b28e-40b5-84ba-353e774982fe"
      defaultMessage="Created from Dropbox"
    />
  ),
  [OrganizationTransactionCreationSource.RESWARE]: (
    <FormattedMessage
      id="55ca1c7f-0ff2-4bcd-901b-6a9d2fcddd4b"
      defaultMessage="Created from Resware"
    />
  ),
});

export function renderTransactionTypeWithVariant(
  transactionType: string | null,
  transactionVariant: OrganizationTransactionVariant,
  isMortgage: boolean,
  requiresNsaMeeting: boolean,
  intl: IntlShape,
  ps1583AttestationEnabled: boolean,
  signTransactionsEnabled?: boolean,
) {
  const requiresMeeting = isMortgage || ESIGN_ONLY_TRANSACTIONS.includes(transactionType ?? "");

  return requiresMeeting ? (
    formattedTransactionType({
      requiresNsaMeeting,
      type: transactionType,
      intl,
      signTransactionsEnabled,
    })
  ) : transactionType ? (
    <>
      {formattedTransactionVariant(transactionVariant, intl, signTransactionsEnabled)} (
      {transactionType})
    </>
  ) : (
    formattedTransactionVariant(
      transactionVariant,
      intl,
      ps1583AttestationEnabled,
      signTransactionsEnabled,
    )
  );
}

function InternalOnlyBadge({ className }: { className?: string }) {
  return (
    <Badge kind="infoSubtle" className={className}>
      <FormattedMessage id="42de966b-994d-4e35-a617-4f0036d10b33" defaultMessage="Internal only" />
    </Badge>
  );
}

export function SummaryDetailWrapper({
  term,
  definition,
}: {
  term: ReactNode;
  definition: ReactNode;
}) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={term} definition={definition} />
  ) : (
    <DeprecatedDetailGridRow title={term}>{definition}</DeprecatedDetailGridRow>
  );
}

function Summary(props: Props) {
  const isKeystone = CURRENT_PORTAL === APP.ADMIN;
  const { bundle, entry: portal, viewer, refetch } = props;
  const viewerOrganization = viewer.user?.organization;
  const notaryProfile = viewer.user?.notaryProfile;
  const { retrievalId, retrievalPin, transaction, completionRequirements, referralInfo, meetings } =
    bundle;
  const execRefetch = () => refetch();
  const intl = useIntl();
  const qrRef = useRef<HTMLImageElement>() as MutableRefObject<HTMLImageElement>;
  const { hasPermissionFor } = usePermissions();
  const [kbaData] = useSignerFailedKba({
    documentBundleId: bundle.id,
    transactionStatus: transaction.detailedStatus,
    kbaRequired: Boolean(
      completionRequirements?.includes(CompletionRequirement.NOTARIZATION) ||
        bundle.participants?.some((participant) => participant?.proofRequirement?.kba),
    ),
  });
  const adminUpdateOrganizationTransactionMutateFn = useMutation(
    AdminUpdateOrganizationTransactionMutation,
  );
  const isDocumentPreviewHidden = !hasPermissionFor("downloadTransactionDocuments") && isKeystone;

  const updateOrganizationTransaction = async (params: {
    activationTime: string | undefined | null;
    activationTimezone: string | undefined | null;
    expiry: string | undefined | null;
    expiryTimezone: string | undefined | null;
  }) => {
    await adminUpdateOrganizationTransactionMutateFn({
      variables: {
        input: {
          id: transaction.id,
          activationTime: params.activationTime,
          activationTimezone: params.activationTimezone,
          expiry: params.expiry,
          expiryTimezone: params.expiryTimezone,
        },
      },
    });
    return execRefetch();
  };

  function detailSection(key: keyof typeof SECTIONS, content: ReactNode) {
    return (
      <DescriptionListItem
        term={intl.formatMessage(SECTIONS[key])}
        definition={content}
        automationId={key}
      />
    );
  }

  function ihnNotaryAssignmentSection(
    transaction: NonNullable<DocumentBundleForTransactionDetailsSummary["transaction"]>,
  ) {
    const isNotarization =
      transaction.transactionVariant === OrganizationTransactionVariant.NOTARIZATION;

    if (!isNotarization || (!transaction.closer && !transaction.notarizeCloserOverride)) {
      return null;
    }

    return detailSection(
      "notaryAssignment",
      // Hard-assigned to a specific notary
      transaction.closer ? (
        <FormattedMessage
          id="809864e1-b300-4ad1-9166-04b45aaf3535"
          defaultMessage="IHN - {notaryName}"
          values={{ notaryName: userFullName(transaction.closer) }}
        />
      ) : (
        // Explicitly assigned to the Notarize network
        <FormattedMessage
          id="5e5a09ab-f8a1-4e7e-87ce-46a97c06c0f9"
          defaultMessage="Notarize network"
        />
      ),
    );
  }

  const verifyLinkSrc = transaction.verifyLinkQrCode
    ? `data:image/svg+xml;base64,${btoa(transaction.verifyLinkQrCode)}`
    : null;

  const isWetSignTransaction = transaction.transactionType === MortgageTransactionType.wet_sign;

  const showAdvancedDetails = Boolean(
    hasPermissionFor("advancedTransactionDetails") ||
      transaction.publicOrganization.featureList.includes(Feature.ADVANCED_TRANSACTION_CREATION),
  );

  const hasCustomEmailFields =
    transaction.message || transaction.messageSignature || transaction.messageSubject;
  const showCustomizedEmail =
    Boolean(transaction.publicOrganization.featureList.includes(Feature.CUSTOM_EMAILS)) &&
    !isWetSignTransaction &&
    hasCustomEmailFields;
  const isMortgageTransaction = transaction.isMortgage;

  const showReadOnlyBlurb =
    !hasPermissionFor("seeNotarizationSummaryReadOnly") &&
    isMortgageTransaction &&
    transaction.userAction !== UserAction.EDIT &&
    transaction.detailedStatus === OrganizationTransactionDetailedStatus.DRAFT;

  const showSignerFailedKbaAlert = kbaData.length > 0;

  const isReschedulerEnabled =
    (!transaction.lenderInitiated || transaction.isCollaborative) &&
    !showReadOnlyBlurb &&
    // at the moment, lenders do NOT want this functionality. they would rather recall and/or recreate the transaction
    // because the docs have to change anyway
    viewerOrganization?.organizationType === OrganizationTypeEnum.TITLE_AGENCY &&
    transaction.status !== OrganizationTransactionStatus.SUCCESS;

  const meetingIdDisplay = () => {
    return (
      <div>
        {hasPermissionFor("meetingDetails") && (
          <span>
            <FormattedMessage
              id="1e902959-3e9c-4ad5-ab60-a1624e63acf2"
              defaultMessage="{meetingCount, plural, one{# meeting has} other{# meetings have}} occurred"
              values={{ meetingCount: meetings.edges.length }}
            />{" "}
          </span>
        )}
        {meetings.edges.map((edge, index) => {
          const meetingId = edge.node.id;
          return (
            <span key={meetingId}>
              {index ? <span>, </span> : ""}
              {hasPermissionFor("meetingDetails") ? (
                <Link underlined={false} to={`/analytics/page/1/${meetingId}/notary`}>
                  {meetingId}
                </Link>
              ) : (
                <span data-automation-id="meeting-id">{meetingId}</span>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const requiredFeature = (feature: RequiredFeature) =>
    bundle.requiredFeatures?.includes(feature) ? (
      <FormattedMessage id="011ccd7a-92e6-4c78-aed8-6650bfece12c" defaultMessage="Yes" />
    ) : (
      <FormattedMessage id="ba2f68a5-2c69-435c-9d8d-78a9a7fe697f" defaultMessage="No" />
    );

  const showTransactionScheduleInfo =
    transaction.activationTime &&
    transaction.expiry &&
    transaction.signingScheduleType &&
    transaction.notaryMeetingTime;

  const sidebarContentDirection = isDocumentPreviewHidden ? "horizontal" : "vertical";

  const showPropertyInfo = [
    OrganizationTypeEnum.TITLE_AGENCY,
    OrganizationTypeEnum.LENDER,
  ].includes(transaction.publicOrganization.organizationType);

  const showSidebarContent =
    showTransactionScheduleInfo || showPropertyInfo || referralInfo || transaction.easylink;

  const { copy, recentlyCopied } = useCopy();

  const activationLinkButton = transaction.activationLink ? (
    <Button
      variant="tertiary"
      buttonSize="condensed"
      buttonColor="action"
      withIcon={{
        name: recentlyCopied ? "tick" : "copy",
        placement: "left",
      }}
      onClick={() => copy(transaction.activationLink || "")}
    >
      {recentlyCopied ? (
        <FormattedMessage id="4d8bb47b-b45c-48da-a7d9-da6a0b6debd6" defaultMessage="Copied!" />
      ) : (
        <FormattedMessage id="fffdb70f-0447-4f5e-b295-1ba5e0f721b5" defaultMessage="Copy" />
      )}
    </Button>
  ) : (
    <FormattedMessage id="1e7fb1f3-ea6b-4ff1-833f-e5bede0969a6" defaultMessage="N/A" />
  );

  const activationLinkLabel = (
    <FormattedMessage id="fee466cc-f18e-4a66-942b-ce6a812f0352" defaultMessage="Activation link" />
  );
  return (
    <>
      {showSignerFailedKbaAlert && (
        <SignerFailedKbaAlert
          kbaFailureList={kbaData}
          timezone={transaction.expiryTimezone || null}
        />
      )}
      <div className={Styles.summaryTabSection}>
        <div className={Styles.summaryPreviewContainer}>
          {!isDocumentPreviewHidden && <DocumentPreviewContainer bundle={bundle} />}
          {showSidebarContent && (
            <div
              data-automation-id="summary-preview-sidebar"
              className={Styles.summaryPreviewSidebar}
            >
              {/* Customer portals "Schedule" section */}
              {!hasPermissionFor("summaryTransactionScheduleInfo") && (
                <SigningTimeSchedule
                  direction={sidebarContentDirection}
                  transactionId={transaction.id}
                  isReschedulerEnabled={isReschedulerEnabled}
                />
              )}
              {/* Keystone "Schedule" section */}
              {hasPermissionFor("transactionDetailsDateTimes") && (
                <AdminTransactionDetailsDateTimes
                  direction={sidebarContentDirection}
                  transaction={transaction}
                  onSubmitActivationForm={(values) => {
                    return updateOrganizationTransaction({
                      activationTime: values?.activation,
                      activationTimezone: values?.activationTimezone,
                      expiry: transaction.expiry,
                      expiryTimezone: transaction.expiryTimezone,
                    });
                  }}
                  onSubmitExpiryForm={(values) => {
                    return updateOrganizationTransaction({
                      activationTime: transaction.activationTime,
                      activationTimezone: transaction.activationTimezone,
                      expiry: values?.expiry,
                      expiryTimezone: values?.expiryTimezone,
                    });
                  }}
                />
              )}
              {showPropertyInfo && (
                <PropertyInfoContainer
                  direction={sidebarContentDirection}
                  portal={portal}
                  transaction={transaction}
                  execRefetch={execRefetch}
                />
              )}
              {referralInfo && (
                <ReferralInfoContainer
                  direction={sidebarContentDirection}
                  referralInfo={referralInfo}
                />
              )}
              {transaction.easylink && (
                <EasyLinkInfoContainer
                  direction={sidebarContentDirection}
                  easyLink={transaction.easylink}
                  defaultPayerSource={transaction.publicOrganization.defaultPayerSource}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className={Styles.summaryTabSection}>
        <Card
          fullWidth
          noMargin
          header={
            <CardHeading>
              <FormattedMessage
                id="d852d63d-47a5-4daf-858e-1b6673a55a3d"
                defaultMessage="Additional details"
              />
            </CardHeading>
          }
        >
          <DetailsDescriptionList isTxnDetailsRedesign>
            {detailSection("transactionName", transaction.name)}
            {detailSection("transactionId", transaction.id)}
            {transaction.verifyLink ? (
              detailSection(
                "accessPortal",
                <Link href={transaction.verifyLink}>
                  <FormattedMessage
                    id="d6369516-b9dc-408c-bea1-5023119686a3"
                    defaultMessage="View documents"
                  />
                </Link>,
              )
            ) : (
              <>
                {retrievalId && detailSection("accessId", retrievalId)}
                {retrievalPin &&
                  hasPermissionFor("downloadTransactionDocuments") &&
                  !isNotaryDocumentDownloadProhibited(notaryProfile, transaction) &&
                  detailSection("accessPin", retrievalPin)}
              </>
            )}
            {showAdvancedDetails &&
              transaction.externalId &&
              detailSection("externalId", transaction.externalId)}

            {(hasPermissionFor("meetingDetails") || meetings.edges.length > 0) &&
              detailSection("meetings", meetingIdDisplay())}
            {hasPermissionFor("summaryCreationSource") &&
              detailSection(
                "creationSource",
                CREATION_SOURCE_LABELS[transaction.creationSource] || transaction.creationSource,
              )}
            {ihnNotaryAssignmentSection(transaction)}

            <PaymentStatus
              bundle={bundle}
              canShowPaymentInTransactionForInvoicing={Boolean(
                viewerOrganization?.canShowPaymentInTransactionForInvoicing,
              )}
              renderPriceBreakdown={(charge) => (
                <PriceBreakdownWithRefund refetch={execRefetch} charge={charge} />
              )}
              adminCapabilities={hasPermissionFor("paymentStatus")}
              onUpdate={execRefetch}
              data-automation-id="payment-status"
            />
          </DetailsDescriptionList>

          {/* Keystone only */}
          {isKeystone && (
            <div className={Styles.internalOnlySection}>
              <InternalOnlyBadge />
              <DetailsDescriptionList isTxnDetailsRedesign>
                <TransactionOrganizationDisplay transactionOrg={transaction.publicOrganization} />
                {detailSection(
                  "credibleWitness",
                  requiredFeature(RequiredFeature.CREDIBLE_WITNESS),
                )}
                {detailSection("transactionWitness", requiredFeature(RequiredFeature.WITNESS))}
              </DetailsDescriptionList>
              {hasPermissionFor("summaryPayerInfo") && (
                <DetailsDescriptionList isTxnDetailsRedesign>
                  {!bundle.charges?.length && (
                    <DescriptionListItem
                      term={
                        <FormattedMessage
                          id="adedff8d-3a16-437e-8e37-e3dbdbaa4a81"
                          defaultMessage="Payer"
                        />
                      }
                      definition={
                        <PayerType
                          // PayerType only handles updating transaction.payer
                          // if there are charges then the PayerType field will be rendered in the charge details
                          canUpdate={Boolean(transaction)}
                          bundle={bundle}
                          payerType={bundle.payer}
                          onUpdate={execRefetch}
                          organizationPayer={transaction.publicOrganization.defaultPayerSource}
                        />
                      }
                    />
                  )}
                  {hasPermissionFor("markBundleFree") && (
                    <TransactionMarkFree onUpdate={execRefetch} bundle={bundle} />
                  )}
                </DetailsDescriptionList>
              )}
              {hasPermissionFor("summaryPayerInfo") && (
                <DescriptionList>
                  <DescriptionListItem
                    term={activationLinkLabel}
                    definition={activationLinkButton}
                  />
                </DescriptionList>
              )}
            </div>
          )}
        </Card>
        {showCustomizedEmail && <CustomEmailDetails transaction={transaction} />}
      </div>

      {/* Keystone only */}
      {isKeystone && Boolean(verifyLinkSrc) && (
        <div className={Styles.summaryTabSection}>
          <Card
            fullWidth
            noMargin
            header={
              <div className={Styles.qrCodeHeading}>
                <div className={Styles.row}>
                  <CardHeading>
                    <FormattedMessage
                      id="d852d63d-47a5-4daf-858e-1b6673a55a3d"
                      defaultMessage="Certify QR code"
                    />
                    <InternalOnlyBadge className={Styles.internalBadge} />
                  </CardHeading>
                  <Button
                    variant="primary"
                    buttonColor="action"
                    onClick={() =>
                      downloadImageAsPNG(
                        qrRef.current,
                        `${transaction.publicOrganization.id}_${transaction.id}_certify_qr_code`,
                        300,
                        300,
                      )
                    }
                    withIcon={{ name: "download", placement: "right" }}
                  >
                    <FormattedMessage
                      id="074acdd3-70f6-4673-9082-a771e953b4f1"
                      defaultMessage="Download QR code"
                    />
                  </Button>
                </div>
                <div>
                  <Paragraph textColor="subtle" size="small">
                    <FormattedMessage
                      id="174bf8c2-69fa-4a16-b434-f65f2051623d"
                      defaultMessage="Download this QR code to append a physical document for a certify transaction."
                    />
                  </Paragraph>
                </div>
              </div>
            }
          >
            <div>
              <img
                className={Styles.verifyQrCode}
                data-automation-id="verify-link-qr-code"
                src={verifyLinkSrc!}
                alt={intl.formatMessage(MESSAGES.qrCodeAlt)}
                ref={qrRef}
              />
            </div>
          </Card>
        </div>
      )}

      <div className={Styles.summaryTabSection}>
        {isKeystone && hasPermissionFor("summaryPayerInfo") && (
          <>
            <AdminTools organizationTransactionId={transaction.id} documentBundleId={bundle.id} />
          </>
        )}
        {hasHacksEnabled && <TransactionHacks documentBundleId={bundle.id} />}
      </div>
    </>
  );
}

export function SummaryContainer({ bundle, entry, viewer, refetch }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().transactionDetailsSummary),
  });

  return isTxnDetailsRedesign ? (
    <Summary bundle={bundle} entry={entry} viewer={viewer} refetch={refetch} />
  ) : (
    <DeprecatedSummary bundle={bundle} entry={entry} viewer={viewer} refetch={refetch} />
  );
}
