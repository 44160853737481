import { CURRENT_EXPERIMENTS, useExperimentGroup } from "common/experiments/group";
import { useActiveOrganization } from "common/account/active_organization";

export function useSimpleTransactionCreationV3({ skip }: { skip: boolean }): boolean {
  const experiment = CURRENT_EXPERIMENTS.SimpleTxnCreation;
  const [activeOrganizationId] = useActiveOrganization();

  const simpleTxnCreationExpt = useExperimentGroup({
    flag: experiment.flag,
    skip,
    organization: { id: activeOrganizationId || "", featureFlags: [] },
    possibleGroups: [experiment.A],
    precedence: "ld",
  });

  return simpleTxnCreationExpt === experiment.A;
}
